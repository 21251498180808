//full width llc page

var GblTop;
function GetVertOffset(srchStr)
{
    GblTop = $(srchStr).offset().top;
}

if ($('.full-width-llc').length>0) {
	GetVertOffset('.sticky-ppc-ul-container');     //-- Sets GblTop
    $(window).scroll(function () {
    	GetVertOffset('.sticky-ppc-ul-container');
        var y = $(window).scrollTop();
        if (y >= GblTop) {
            $('.sticky-ppc-ul').addClass('fixed-ppc');
        } else {
            $('.sticky-ppc-ul').removeClass('fixed-ppc');
        }
    });


    $('.accordion2:first-of-type i').addClass('flipped');
    $('.accordion1 .header, .accordion2 .header').on('click', function () {
        var body = $(this).next().next();
        var icon = $(this).next();

        body.slideToggle('500');
        icon.toggleClass('flipped');

    });
}
//full width llc page


$(function () {
	$('.menu-toggle, .body-overlay').on('click', function () {
		$('.header, .body-overlay, body').toggleClass('open');


	});

	$(window).on('load', function () {
		if (( $('.basic-page').length || $('.sticky-side-bar').length ) && $('.the-content').length ) {
			var minHeight = $('.sticky-side-bar').height();
			$('.primary-content .the-content').css({
				'minHeight' : minHeight + 24
			});

			function getScroll() {
				var b = document.body;
				var e = document.documentElement;
				return {
					left: parseFloat(window.pageXOffset || b.scrollLeft || e.scrollLeft),
					top: parseFloat(window.pageYOffset || b.scrollTop || e.scrollTop)
				};
			}

			var myHeader = $('.the-content').last();
			myHeader.data('position', myHeader.position());

			$(window).scroll(function () {
				var hPos = myHeader.data('position'), scroll = getScroll();
				if (hPos.top < scroll.top ) {
					$('.sticky-side-bar').addClass('fixed');
				} else {
					$('.sticky-side-bar').removeClass('fixed');
				}
			});

			$(window).resize(function () {

				myHeader.data('position', myHeader.position());
				var hPos = myHeader.data('position'), scroll = getScroll();
				if (hPos.top < scroll.top ) {
					$('.sticky-side-bar').addClass('fixed');
				} else {
					$('.sticky-side-bar').removeClass('fixed');
				}
			});
		}
	});

	$('.search-icon').on('click', function () {
		$('.search-overlay').addClass('show-search');
		$('.header-container .search input ').focus();
	});
	$('.search-overlay, main, footer').on('click', function (e) {
		if (!$(e.target).hasClass('search') && !$(e.target).parents('.search').hasClass('search') ) {
			$('.search-overlay').removeClass('show-search');
		}
	});

    $('.entire-card-is-button').on('click', function () {
        var btn = $(this).find('.button').first();

        if ($(btn).length) {
            var href = $(btn).attr('href');
            var target = $(btn).attr('target');

            if (href.length) {
                window.open(href, target.length ? target : '_self');
            }
        }
    })
});
