$(function () {
    // Slick Library (used in testimonial block, potential to be used in other blocks with slider functionality)
    // https://kenwheeler.github.io/slick/
    if ($("body").is('.page-template-block-based-landing-page, .block_listing-template-default')) {

        // find the card containers and add the Slick library to it
        $("div.carousel-wrapper > .carousel-container").slick({
            dots: false,
            centerMode: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: '<button type="button" className="slick-prev"></button>',
            nextArrow: '<button type="button" className="slick-next"></button>',
            responsive: [
                {
                    breakpoint: 1800,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false,
                        centerMode: false,
                    }
                },

                {
                    breakpoint: 1500,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false,
                        centerMode: false,
                    }
                },
                {
                    breakpoint: 876,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false,
                        centerMode: false,
                    }
                },

                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: false,
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
    }

    // TABLES BLOCK SCRIPTS
    // wraps scrollable tables on medium and smaller viewports
    function scrollWrap() {
        $('table').wrap('<div class="scroll-wrapper"></div>');
        $('.scroll-wrapper').before('<div class="scroll-header">Swipe to view more content</div>');
    }
    scrollWrap();
    // show or hide table scroll header based on if overflowing its parent
    function handleScrollResize() {
        $('table').each(function (index) {
            var table = $(this);
            var tableWidth = table.outerWidth();
            var parentWidth = table.parent().outerWidth();
            var scrollHeader = table.parent().prevAll('.scroll-header:first');

            if (tableWidth > parentWidth) {
                // element has overflow
                scrollHeader.show();
            } else {
                // element doesn't overflow
                scrollHeader.hide();
            }
        })
    }
    $(window).on("resize", function () {
        handleScrollResize();
    });
    $(document).ready(function () {
        handleScrollResize();
    });

});
